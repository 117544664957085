import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class TicketService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'ticket', { headers: authHeader() });
    }
    order(formData) {
        return axios.post(VUE_APP_BASE_URL + 'ticket-order', formData, { headers: authHeader() });
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL + 'ticket', formData, { headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL + 'ticket/' + formData.id, formData, { headers: authHeader() });
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL + 'ticket/' + id, { headers: authHeader() });
    }
    payTicket(formData) {
        return axios.post(VUE_APP_BASE_URL + 'ticket-pay', formData, { headers: authHeader() });
    }
    mergeTicket(formData) {
        return axios.post(VUE_APP_BASE_URL + 'ticket-merge', formData, { headers: authHeader() });
    }
    closeTicket(ticket_id){
        return axios.get(VUE_APP_BASE_URL + 'ticket-close/' + ticket_id, { headers: authHeader() });
    }
    voidTicket(ticket_id){
        return axios.get(VUE_APP_BASE_URL + 'ticket-void/' + ticket_id, { headers: authHeader() });
    }
    giftTicket(ticket_id){
        return axios.get(VUE_APP_BASE_URL + 'ticket-gift/' + ticket_id, { headers: authHeader() });
    }
    change(resource) {
        return axios.post(VUE_APP_BASE_URL + 'ticket-change-customer', resource, { headers: authHeader() })
    }
}

export default new TicketService()