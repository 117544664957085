import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Main from './views/Layout/Main';
import Blank from './views/Layout/Blank';
import Default from './views/Layout/Default';
import Select2 from "vue3-select2-component";
import 'vue3-autocomplete/dist/vue3-autocomplete.css'

createApp(App)
.use(store)
.use(router)
.component('Select2', Select2)
.component('main-layout', Main)
.component('blank-layout', Blank)
.component('default-layout', Default)
.mount("#app");
