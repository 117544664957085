// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ReservationService {
    getAll() {
        return axios.get(VUE_APP_BASE_URL + 'room-reservation', { headers: authHeader() })
    }
    getReserved() {
        return axios.get(VUE_APP_BASE_URL + 'room-reserved', { headers: authHeader() })
    }
    getAvailableRooms() {
        return axios.get(VUE_APP_BASE_URL + 'room-available', { headers: authHeader() })
    }
    store(resource) {
        axios.post('https://localhost/apps/bullapos/sdk/public/api/room-enable', resource, { headers: authHeader() })
        return axios.post(VUE_APP_BASE_URL + 'room-reservation', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'room-reservation/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'room-reservation/' + resource.id, { headers: authHeader() })
    }
    complete(resource) {
        axios.post('https://localhost/apps/bullapos/sdk/public/api/room-disable', resource, { headers: authHeader() })
        return axios.get(VUE_APP_BASE_URL + 'room-reservation-complete/' + resource.id, { headers: authHeader() })
    }
    toggleEnable(resource) {
        axios.post('https://localhost/apps/bullapos/sdk/public/api/room-toggle-enable', resource, { headers: authHeader() })
        return axios.post(VUE_APP_BASE_URL + 'room-toggle-enable', resource, { headers: authHeader() })
    }

}

export default new ReservationService()