<template>
    <div>
      <div
        class="card mb-3"
        id="ordersTable"
        data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
      >
        <div class="card-header">
          <div class="row flex-between-center border-bottom pb-1">
            <div class="col-sm-4 col-xs-4 align-items-center pe-0">
              <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                <i class="fa fa-shopping-cart text-warning"></i>
                Invoices
              </h5>
              <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">Generate invoices</p>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
              <div id="orders-actions" class="d-flex">
                <button
                  class="btn btn-sm btn-rounded btn-falcon-warning ms-auto"
                  type="button"
                  @click="backToInvoices"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <i class="fas fa-plus"></i>
                  Back
                </button>
                <button
                  @click="forceReload"
                  class="btn btn-sm btn-falcon-warning ms-2"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Refresh page"
                >
                  <i class="fa-solid fa-arrows-rotate"></i> Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
        


        
          <!-- start : Invoice  -->
          
          <div class="card mb-3">
            <div class="card-body">
              <div class="row align-items-center text-center mb-3">
                <div class="col-sm-6 text-sm-start"><img src="assets/img/logos/logo-invoice.png" alt="invoice" width="150" /></div>
                <div class="col text-sm-end mt-3 mt-sm-0">
                  <h2 class="mb-3">Invoice</h2>
                  <h5>Falcon Design Studio</h5>
                  <p class="fs--1 mb-0">156 University Ave, Toronto<br />On, Canada, M5H 2H7</p>
                </div>
                <div class="col-12">
                  <hr />
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-500">Invoice to</h6>
                  <h5>Antonio Banderas</h5>
                  <p class="fs--1">1954 Bloor Street West<br />Torronto ON, M6P 3K9<br />Canada</p>
                  <p class="fs--1"><a href="mailto:example@gmail.com">example@gmail.com</a><br /><a href="tel:444466667777">+4444-6666-7777</a></p>
                </div>
                <div class="col-sm-auto ms-auto">
                  <div class="table-responsive">
                    <table class="table table-sm table-borderless fs--1">
                      <tbody>
                        <tr>
                          <th class="text-sm-end">Invoice No:</th>
                          <td>14</td>
                        </tr>
                        <tr>
                          <th class="text-sm-end">Order Number:</th>
                          <td>AD20294</td>
                        </tr>
                        <tr>
                          <th class="text-sm-end">Invoice Date:</th>
                          <td>2018-09-25</td>
                        </tr>
                        <tr>
                          <th class="text-sm-end">Payment Due:</th>
                          <td>Upon receipt</td>
                        </tr>
                        <tr class="alert alert-success fw-bold">
                          <th class="text-sm-end">Amount Due:</th>
                          <td>$19688.40</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="table-responsive scrollbar mt-4 fs--1">
                <table class="table table-striped border-bottom">
                  <thead class="light">
                    <tr class="bg-primary text-white dark__bg-1000">
                      <th class="border-0">Products</th>
                      <th class="border-0 text-center">Quantity</th>
                      <th class="border-0 text-end">Rate</th>
                      <th class="border-0 text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="align-middle">
                        <h6 class="mb-0 text-nowrap">Platinum web hosting package</h6>
                        <p class="mb-0">Down 35mb, Up 100mb</p>
                      </td>
                      <td class="align-middle text-center">2</td>
                      <td class="align-middle text-end">$65.00</td>
                      <td class="align-middle text-end">$130.00</td>
                    </tr>
                    <tr>
                      <td class="align-middle">
                        <h6 class="mb-0 text-nowrap">2 Page website design</h6>
                        <p class="mb-0">Includes basic wireframes and responsive templates</p>
                      </td>
                      <td class="align-middle text-center">1</td>
                      <td class="align-middle text-end">$2,100.00</td>
                      <td class="align-middle text-end">$2,100.00</td>
                    </tr>
                    <tr>
                      <td class="align-middle">
                        <h6 class="mb-0 text-nowrap">Mobile App Development</h6>
                        <p class="mb-0">Includes responsive navigation</p>
                      </td>
                      <td class="align-middle text-center">8</td>
                      <td class="align-middle text-end">$5,00.00</td>
                      <td class="align-middle text-end">$4,000.00</td>
                    </tr>
                    <tr>
                      <td class="align-middle">
                        <h6 class="mb-0 text-nowrap">Web App Development</h6>
                        <p class="mb-0">Includes react spa</p>
                      </td>
                      <td class="align-middle text-center">6</td>
                      <td class="align-middle text-end">$2,000.00</td>
                      <td class="align-middle text-end">$12,000.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <table class="table table-sm table-borderless fs--1 text-end">
                    <tr>
                      <th class="text-900">Subtotal:</th>
                      <td class="fw-semi-bold">$18,230.00 </td>
                    </tr>
                    <tr>
                      <th class="text-900">Tax 8%:</th>
                      <td class="fw-semi-bold">$1458.40</td>
                    </tr>
                    <tr class="border-top">
                      <th class="text-900">Total:</th>
                      <td class="fw-semi-bold">$19688.40</td>
                    </tr>
                    <tr class="border-top border-top-2 fw-bolder text-900">
                      <th>Amount Due:</th>
                      <td>$19688.40</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer bg-light">
              <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if there’s anything else we can do, please let us know!</p>
            </div>
          </div>
          <footer class="footer">
            <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
              <div class="col-12 col-sm-auto text-center">
                <p class="mb-0 text-600">Thank you for creating with Falcon <span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2022 &copy; <a href="https://themewagon.com">Themewagon</a></p>
              </div>
              <div class="col-12 col-sm-auto text-center">
                <p class="mb-0 text-600">v3.13.0</p>
              </div>
            </div>
          </footer>
          <!-- End : Invoice  -->

        
      </div>
  
      <!-- Start edit modal  -->
      <div
        class="modal fade"
        id="edit-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="edit-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit Products</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  <div v-if="show_location_error" class="alert alert-danger" role="alert">
                    You must select at least one location!
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row mt-2">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name">Name</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            v-model="formData.NAME"
                            :class="
                              v$.formData.NAME.$dirty && v$.formData.NAME.$invalid
                                ? `is-invalid`
                                : v$.formData.NAME.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.NAME.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Select Group</label
                          >
                          <select
                            class="form-select"
                            id="recipient-name"
                            type="text"
                            v-model="formData.GROUP_ID"
                            @change="groupSelected"
                            :class="
                              v$.formData.GROUP_ID.$dirty && v$.formData.GROUP_ID.$invalid
                                ? `is-invalid`
                                : v$.formData.GROUP_ID.$dirty
                                ? `is-valid`
                                : ``
                            "
                          >
                            <option value="">Group...</option>
                            <option v-for="(item, i) in groups" :key="i" :value="item.ID">
                              {{ item.NAME }}
                            </option>
                          </select>
                          <div
                            v-for="error of v$.formData.GROUP_ID.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Buying Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="number"
                            v-model="formData.BUY_PRICE"
                            :class="
                              v$.formData.BUY_PRICE.$dirty && v$.formData.BUY_PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.BUY_PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.BUY_PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Selling Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="number"
                            v-model="formData.PRICE"
                            :class="
                              v$.formData.PRICE.$dirty && v$.formData.PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text">WS Price</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 5"
                            v-model="formData.WS_PRICE"
                          />
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name">Photo</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                            @change="onFileChange"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Reorder Level</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 5"
                            v-model="formData.reorder_level"
                            :class="
                              v$.formData.reorder_level.$dirty &&
                              v$.formData.reorder_level.$invalid
                                ? `is-invalid`
                                : v$.formData.reorder_level.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.reorder_level.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Stock Quantity</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            disabled
                            placeholder="e.ge 10"
                            v-model="formData.STOCK_AMOUNT"
                            :class="
                              v$.formData.STOCK_AMOUNT.$dirty &&
                              v$.formData.STOCK_AMOUNT.$invalid
                                ? `is-invalid`
                                : v$.formData.STOCK_AMOUNT.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.STOCK_AMOUNT.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <!-- ORDER TYPES QUANTITIES  -->
                        <div
                          v-for="(item, i) in selected_order_types"
                          :key="i"
                          class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <label class="col-form-label" for="message-text"
                            >{{ item.NAME.toUpperCase() }} Quantity</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :value="
                              location_quantities.find((el) => el.location_id == item.ID)
                                ? location_quantities.find(
                                    (el) => el.location_id == item.ID
                                  ).quantity
                                : 0
                            "
                            @change="addLocationQty($event, item)"
                          />
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <label class="col-form-label" for="message-text">Barcode</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            v-model="formData.BARCODE"
                          />
                        </div>
                        <label class="col-form-label" for="message-text">Description</label>
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.DESCRIPTION"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </p>
  
                        <div
                          v-if="formData.BARCODE && formData.BARCODE.length > 0"
                          class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <div class="border border-success text-center">
                            <label
                              class="col-form-label mb-0 text-decoration-underline"
                              for="recipient-name"
                              >{{ formData.NAME ? formData.NAME.toUpperCase() : "" }}</label
                            >
                            <br />
                            <p>{{ formData.DESCRIPTION }}</p>
                            <div>
                              <vue-barcode :value="formData.BARCODE" :height="50" />
                            </div>
                            <span>{{ formatToCurrency(formData.PRICE) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Other Actions</div>
                  </div>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.VISIBLE ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleSellable"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.sellable ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>Sellable?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleVAT"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.vat ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>VAT(16%)
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <label class="col-form-label" for="message-text">Size</label>
                      <select
                        class="form-select nav-link nav-link-card-details"
                        id="recipient-name"
                        type="text"
                        v-model="formData.UNIT_NAME"
                      >
                        <option value="">Unit...</option>
                        <option
                          v-for="(item, i) in menu_item_sizes"
                          :key="i"
                          :value="item.NAME"
                        >
                          {{ item.NAME }}
                        </option>
                      </select>
                    </li>
                    <hr />
  
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Select Locations</div>
                    </div>
                    <div
                      class="border"
                      :class="show_location_error ? 'border-danger' : 'border-success'"
                    >
                      <li
                        v-for="(item, i) in order_types"
                        :key="i"
                        class="nav-item me-2 me-lg-0 my-2"
                      >
                        <a
                          @click="addToLocations(item)"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="
                            formData.order_types && formData.order_types.includes(item.ID)
                              ? `bg-info text-white`
                              : ``
                          "
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                      </li>
                    </div>
  
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Actions</div>
                    </div>
                    <button class="d-flex ms-uto btn btn-falcon-primary">
                      Print Barcode
                    </button>
                  </ul>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="updateResource"
              >
                <span class="fa fa-save me-2" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
  
      <!-- Start edit modal  -->
      <div
        class="modal fade"
        id="addModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        :aria-labelledby="edit - modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  <div v-if="show_location_error" class="alert alert-danger" role="alert">
                    You must select at least one location!
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row mt-2">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name">Name</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g Pilau"
                            v-model="formData.NAME"
                            :class="
                              v$.formData.NAME.$dirty && v$.formData.NAME.$invalid
                                ? `is-invalid`
                                : v$.formData.NAME.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.NAME.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Select Group</label
                          >
                          <select
                            class="form-select"
                            id="recipient-name"
                            type="text"
                            v-model="formData.GROUP_ID"
                            @change="groupSelected"
                            :class="
                              v$.formData.GROUP_ID.$dirty && v$.formData.GROUP_ID.$invalid
                                ? `is-invalid`
                                : v$.formData.GROUP_ID.$dirty
                                ? `is-valid`
                                : ``
                            "
                          >
                            <option value="">Group...</option>
                            <option v-for="(item, i) in groups" :key="i" :value="item.ID">
                              {{ item.NAME }}
                            </option>
                          </select>
                          <div
                            v-for="error of v$.formData.GROUP_ID.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Buying Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="number"
                            placeholder="e.g 5"
                            v-model="formData.BUY_PRICE"
                            :class="
                              v$.formData.BUY_PRICE.$dirty && v$.formData.BUY_PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.BUY_PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.BUY_PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Selling Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="number"
                            placeholder="e.g 5"
                            v-model="formData.PRICE"
                            :class="
                              v$.formData.PRICE.$dirty && v$.formData.PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text">WS Price</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 5"
                            v-model="formData.WS_PRICE"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Reorder Level</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 5"
                            v-model="formData.reorder_level"
                            :class="
                              v$.formData.reorder_level.$dirty &&
                              v$.formData.reorder_level.$invalid
                                ? `is-invalid`
                                : v$.formData.reorder_level.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.reorder_level.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name">Photo</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                            @change="onFileChange"
                          />
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Stock Quantity</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="number"
                            disabled
                            placeholder="e.g 10"
                            v-model="formData.STOCK_AMOUNT"
                            :class="
                              v$.formData.STOCK_AMOUNT.$dirty &&
                              v$.formData.STOCK_AMOUNT.$invalid
                                ? `is-invalid`
                                : v$.formData.STOCK_AMOUNT.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.STOCK_AMOUNT.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <!-- ORDER TYPES QUANTITIES  -->
                        <div
                          v-for="(item, i) in selected_order_types"
                          :key="i"
                          class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        >
                          <label class="col-form-label" for="message-text"
                            >{{ item.NAME.toUpperCase() }} Quantity</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 5"
                            @change="addLocationQty($event, item)"
                          />
                        </div>
  
                        <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <label class="col-form-label" for="message-text">Barcode</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            v-model="formData.BARCODE"
                          />
                        </div>
                        <label class="col-form-label" for="message-text">Description</label>
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.DESCRIPTION"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Other Actions</div>
                  </div>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.VISIBLE ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleSellable"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.sellable ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>Sellable?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleVAT"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.vat ? `bg-warning text-white` : ``"
                        ><span class="fa fa-question me-2"></span>VAT(16%)
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <label class="col-form-label" for="message-text">Size</label>
                      <select
                        class="form-select nav-link nav-link-card-details"
                        id="recipient-name"
                        type="text"
                        v-model="formData.UNIT_NAME"
                      >
                        <option value="">Unit...</option>
                        <option
                          v-for="(item, i) in menu_item_sizes"
                          :key="i"
                          :value="item.NAME"
                        >
                          {{ item.NAME }}
                        </option>
                      </select>
                    </li>
                    <hr />
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Select Locations</div>
                    </div>
                    <div
                      class="border"
                      :class="show_location_error ? 'border-danger' : 'border-success'"
                    >
                      <li
                        v-for="(item, i) in order_types"
                        :key="i"
                        class="nav-item me-2 me-lg-0 my-2"
                      >
                        <a
                          @click="addToLocations(item)"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="
                            formData.order_types && formData.order_types.includes(item.ID)
                              ? `bg-info text-white`
                              : ``
                          "
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="saveResource"
              >
                <span class="fa fa-save me-2" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End add modal  -->
  
      <!-- Start edit modal  -->
      <div
        class="modal fade"
        id="import-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="import-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div v-if="show_location_error" class="alert alert-danger" role="alert">
                    You must select atleast one location!
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row mt-2">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <input type="file" id="import-excel" @change="importExcel()" />
                        </div>
                      </div>
                      <hr class="my-2" />
                      <div class="table-responsive">
                        <table class="table">
                          <caption>
                            List of items
                          </caption>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Description</th>
                              <th scope="col">Buying Price</th>
                              <th scope="col">Selling Price</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Units</th>
                              <th scope="col">Category</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in import_resources" :key="i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td>{{ item[0] }}</td>
                              <td>{{ item[1] }}</td>
                              <td>{{ item[2] }}</td>
                              <td>{{ item[3] }}</td>
                              <td>{{ item[4] }}</td>
                              <td>{{ item[5] }}</td>
                              <td>{{ item[6] }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-falcon-warning ms-2 rounded-pill h1 d-block"
                type="button"
                @click="uploadData"
              >
                <span class="d-none d-sm-inline-block ms-1">
                  <i class="fa fa-cloud"></i> Upload</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End import modal  -->
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  import mixins from "../mixins/index";
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  import "flatpickr/dist/flatpickr.css";
  import Pagination from "v-pagination-3";
  import AppBar from "@/components/AppBar.vue";
  import { useVuelidate } from "@vuelidate/core";
  import VueBarcode from "@chenfengyuan/vue-barcode";
  import flatPickr from "vue-flatpickr-component";
  import EasyDataTable from "vue3-easy-data-table";
  import CartService from "../services/cart.service";
  import NavDrawer from "@/components/NavDrawer.vue";
  import FooterComp from "@/components/FooterComp.vue";
  import { required, email } from "@vuelidate/validators";
  import ProductService from "../services/product.service";
  import LoaderOverlay from "@/components/LoaderOverlay.vue";
  import MenuItemsService from "../services/menu_item.service";
  import MenuGroupService from "../services/menu_group.service";
  import MenuItemSizeService from "../services/menu_item_size.service";
  import OrderTypeService from "../services/order_types.service";
  
  import readXlsFile from "read-excel-file";
  import exportXlsFile from "export-from-json";
  
  const now = new Date();
  
  export default {
    mixins: [mixins],
    name: "CustomersView",
    setup() {
      return { v$: useVuelidate() };
    },
    validations() {
      return {
        formData: {
          NAME: { required },
          PRICE: { required },
          GROUP_ID: { required },
          BUY_PRICE: { required },
          STOCK_AMOUNT: { required },
          reorder_level: { required },
        },
      };
    },
    components: {
      NavDrawer,
      AppBar,
      flatPickr,
      Pagination,
      VueBarcode,
      FooterComp,
      LoaderOverlay,
      EasyDataTable,
    },
    data() {
      return {
        per_page: 18,
        current_page: 0,
        records: 0,
        search_term: "",
        file: "",
        order_type: "",
        order_types: [],
        selected_order_types: [],
        filename: "",
        selected: [],
        groups: [],
        searchValue: "",
        table_keys: 1,
        selected_items: "",
        import_resources: [],
        menu_item_sizes: [],
        productCategories: [],
        selected_category: "",
        formData: {
          vat: 0,
          GROUP_ID: "",
          ACTIVE: 0,
          VISIBLE: 1,
          BARCODE: "",
          UNIT_NAME: "",
          UNIT_NAME: "",
          DESCRIPTION: "",
          order_types: [],
          BARCODE: "",
          reorder_level: 0,
          sellable: 0,
        },
        resources: [],
        location_quantities: [],
        orders_table_key: 0,
        show_location_error: false,
        searchField: ["ID", "NAME", "PRICE"],
        headers: [
          { text: "#", value: "ID", sortable: true, width: 10 },
          { text: "Photo", value: "IMAGE", sortable: true, width: 10 },
          { text: "Name", value: "NAME", sortable: true },
          { text: "Group", value: "GROUP_ID", sortable: true },
          { text: "B.Price", value: "BUY_PRICE", sortable: true },
          { text: "S.Price", value: "PRICE", sortable: true },
          { text: "Details", value: "DESCRIPTION", sortable: true },
          { text: "Barcode", value: "BARCODE", sortable: true },
          { text: "Stock Amount", value: "STOCK_AMOUNT", sortable: true },
          { text: "Units", value: "UNIT_NAME", sortable: true },
          { text: "Action", value: "action" },
        ],
      };
    },
    created() {
      console.log(this.$route.params)
      alert(this.$route.params.id)
    },
  
    computed: {
      loading() {
        return this.$store.getters.getLoading;
      },
      theme() {
        return localStorage.getItem("theme");
      },
      user() {
        return JSON.parse(localStorage.getItem("user"));
      },
    },
    methods: {
      closeModal() {
        $("#addModal").hide();
        $(".modal-backdrop").remove();
        $(".content").scrollTop();
        // $('.modal-backdrop').removeClass('show')
      },
      addLocationQty(e, location) {
        let data = {
          quantity: parseInt(e.target.value),
          location_id: location.ID,
        };
        // exist
        let exist = this.location_quantities.find((el) => el.location_id == location.ID);
        if (exist) {
          this.location_quantities.map((el) => {
            if (el.location_id == location.ID) {
              el.quantity = parseInt(e.target.value);
            }
          });
        } else {
          this.location_quantities.push(data);
        }
        this.formData.STOCK_AMOUNT = this.location_quantities.reduce(
          (acc, curr) => acc + curr.quantity, 0,
        );
      },
      myCallback(page) {
        this.page = page;
        this.getMenuItems(page);
      },
      async uploadData() {
        this.$store.commit("SET_LOADING", true);
        console.log(this.import_resources);
        let formatData = await this.import_resources.map((element) => {
          return {
            NAME: element[0],
            DESCRIPTION: element[1],
            BUY_PRICE: element[2],
            PRICE: element[3],
            STOCK_AMOUNT: element[4],
            UNIT_NAME: element[5],
          };
        });
        console.log(formatData);
        let json_data = JSON.stringify(formatData);
        await MenuItemsService.import({ excel_data: json_data }).then(
          (response) => {
            if (response.data.code == 200) {
              console.log(response.data);
              this.page = 1;
              this.getMenuItems(1);
              return response.data.data;
            } else {
              return [];
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
            return [];
          }
        );
        toast.success("Items Uploaoded successfuly!");
        this.$store.commit("SET_LOADING", false);
      },
      importExcel() {
        const input = document.getElementById("import-excel");
        readXlsFile(input.files[0]).then((rows) => {
          this.import_resources = rows.filter((item, index) => index != 0);
        });
      },
      async exportData() {
        this.$store.commit("SET_LOADING", true);
        const data = await MenuItemsService.all(this.order_type).then(
          (response) => {
            if (response.data.code == 200) {
              console.log(response.data);
              return response.data.data;
            } else {
              return [];
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
            return [];
          }
        );
        const fileName = "BullaPOs Expoorted Products";
        const exportType = exportXlsFile.types.xls; // xls
        exportXlsFile({ data, fileName, exportType });
        this.$store.commit("SET_LOADING", false);
      },
      searchItem() {
        this.page = 1;
        this.current_page = 1;
        let formData = {
          search_term: this.search_term,
        };
        this.$store.commit("SET_LOADING", true);
        return MenuItemsService.menuSearch(formData, this.current_page).then(
          (response) => {
            if (response.data.code == 200) {
              this.resources = response.data.data.data;
              this.records = response.data.data.total;
              this.current_page = response.data.data.current_page;
            } else {
              console.log(response.data.error);
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      },
      goToPOS() {
        this.$router.push("/pos");
      },
      goToBarcode() {
        this.$router.push("/barcode");
      },
      formatToCurrency(amount) {
        if (amount || amount == 0) {
          const internationalNumberFormat = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KES",
          });
          return internationalNumberFormat.format(amount);
        } else {
          return amount;
        }
      },
      backToInvoices() {
        this.$router.push('invoice-report')
      },
      editItem(item) {
        console.log(this.location_quantities);
        this.location_quantities = item.locations.map((el) => {
          return {
            quantity: el.pivot.QUANTITY,
            location_id: el.pivot.ORDER_TYPE_ID,
          };
        });
        this.selected_order_types = this.order_types.filter((el) =>
          item.order_types.includes(el.ID)
        );
        this.show_location_error = false;
        this.formData = item;
      },
      addToLocations(location) {
        if (this.formData.order_types && this.formData.order_types.includes(location.ID)) {
          this.formData.order_types = this.formData.order_types.filter(
            (el) => el != location.ID
          );
          this.selected_order_types = this.selected_order_types.filter(
            (el) => el.ID != location.ID
          );
          this.location_quantities = this.location_quantities.filter(
            (el) => el.location_id != location.ID
          );
        } else {
          this.formData.order_types.push(location.ID);
          this.selected_order_types.push(location);
        }
  
        this.formData.STOCK_AMOUNT = this.location_quantities.reduce(
          (acc, curr) => acc + curr.quantity, 0,
        );
      },
      onFileChange(e) {
        console.log(e.target.files[0]);
        this.filename = "Selected File: " + e.target.files[0].name;
        this.file = e.target.files[0];
      },
  
      async selectOrderType(e) {
        this.order_type = e.target.value;
        await this.getMenuItems(this.page);
        this.$store.commit("SET_LOADING", false);
      },
      getAllOrderTypes() {
        return OrderTypeService.index().then(
          (response) => {
            if (response.data.code == 200) {
              this.order_types = response.data.data;
              this.order_type = response.data.data.at(0).ID;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      },
      // categorySelected(e) {
      //   console.log(e);
      //   this.getMenuItems(e.target.value, 1);
      // },
      sortItems(list) {
        return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
      },
      getMenuCategories() {
        this.$store.commit("SET_LOADING", true);
        return ProductService.getMenuCategories().then(
          (response) => {
            if (response.data.status == "success") {
              this.productCategories = response.data.data;
            } else {
              console.log(response.data.error);
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.handleLogout();
            }
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
      getItems() {
        this.$store.commit("SET_LOADING", true);
        return ProductService.getAll().then(
          (response) => {
            if (response.data.code == 200) {
              this.resources = response.data.data.data;
              this.$store.commit("SET_LOADING", false);
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
      getMenuItems(page) {
        this.$store.commit("SET_LOADING", true);
        console.log("ORDERTYPE", this.order_type);
        return MenuItemsService.byOrder(page, this.order_type).then(
          (response) => {
            if (response.data.code == 200) {
              this.resources = response.data.data.data;
              this.records = response.data.data.total;
              this.current_page = response.data.data.current_page;
              this.$store.commit("SET_LOADING", false);
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
      groupSelected(e) {
        this.formData.GROUP_ID = e.target.value;
      },
      getMenuItemSize() {
        return MenuItemSizeService.index().then(
          (response) => {
            if (response.data.status == "success") {
              this.menu_item_sizes = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      },
      getGroups() {
        return MenuGroupService.index().then(
          (response) => {
            if (response.data.status == "success") {
              this.groups = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      },
      toggleShowOnMain() {
        this.formData.show_on_main_page = !this.formData.show_on_main_page;
      },
      toggleActive() {
        console.log(this.formData);
        this.formData.VISIBLE = !this.formData.VISIBLE;
      },
      toggleVAT() {
        this.formData.vat = !this.formData.vat;
      },
      toggleSellable() {
        this.formData.sellable = !this.formData.sellable;
      },
      toggleRoot() {
        this.formData.is_root = !this.formData.is_root;
      },
      saveResource() {
        this.v$.formData.$touch();
        console.log(this.formData);
        if (this.v$.formData.$invalid) {
          return;
        }
        if (this.formData.order_types.length == 0) {
          this.show_location_error = true;
          return;
        }
        this.$store.commit("SET_LOADING", true);
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("order_types", this.formData.order_types);
        formData.append("NAME", this.formData.NAME);
        formData.append("reorder_level", this.formData.reorder_level);
        formData.append("PRICE", this.formData.PRICE);
        formData.append("WS_PRICE", this.formData.WS_PRICE);
        formData.append("BARCODE", this.formData.BARCODE);
        formData.append("GROUP_ID", this.formData.GROUP_ID);
        formData.append("UNIT_NAME", this.formData.UNIT_NAME);
        formData.append("BUY_PRICE", this.formData.BUY_PRICE);
        formData.append("DESCRIPTION", this.formData.DESCRIPTION);
        formData.append("STOCK_AMOUNT", this.formData.STOCK_AMOUNT);
        formData.append("vat", this.formData.vat ? 1 : 0);
        formData.append("sellable", this.formData.sellable ? 1 : 0);
        formData.append("location_quantities", JSON.stringify(this.location_quantities));
  
        // custom for uploads
        let token = localStorage.getItem("token");
        let config = {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };
        MenuItemsService.store(formData, config).then(
          (response) => {
            if (response.data.code == 200) {
              // Clear modals
              this.page = 1;
              this.current_page = 1;
              this.getMenuItems(this.page);
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            toast.error(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
      updateResource() {
        this.v$.formData.$touch();
        console.log(this.formData);
        if (this.v$.formData.$invalid) {
          return;
        }
        if (this.formData.order_types.length == 0) {
          this.show_location_error = true;
          return;
        }
        this.$store.commit("SET_LOADING", true);
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("ID", this.formData.ID);
        formData.append("order_types", this.formData.order_types);
        formData.append("NAME", this.formData.NAME);
        formData.append("reorder_level", this.formData.reorder_level);
        formData.append("PRICE", this.formData.PRICE);
        formData.append("WS_PRICE", this.formData.WS_PRICE);
        formData.append("BARCODE", this.formData.BARCODE);
        formData.append("GROUP_ID", this.formData.GROUP_ID);
        formData.append("UNIT_NAME", this.formData.UNIT_NAME);
        formData.append("BUY_PRICE", this.formData.BUY_PRICE);
        formData.append("DESCRIPTION", this.formData.DESCRIPTION);
        formData.append("STOCK_AMOUNT", this.formData.STOCK_AMOUNT);
        formData.append("vat", this.formData.vat ? 1 : 0);
        formData.append("sellable", this.formData.sellable ? 1 : 0);
        formData.append("location_quantities", JSON.stringify(this.location_quantities));
        // custom for uploads
        let token = localStorage.getItem("token");
        let config = {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };
        MenuItemsService.update2(formData, config).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getMenuItems();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
      deleteResource(resource) {
        console.log(resource);
        let sure = confirm("Are you sure you want to delete? you cannot undo this action.");
        if (sure) {
          this.$store.commit("SET_LOADING", true);
          MenuItemsService.delete(resource).then(
            (response) => {
              console.log(response);
              if (response.data.code == 200) {
                toast.success(response.data.message, {
                  autoClose: 2000,
                });
                this.$store.commit("SET_LOADING", false);
                this.getMenuItems();
              } else {
                console.log(response.data.error);
                toast.error(response.data.error, {
                  autoClose: 2000,
                });
                this.$store.commit("SET_LOADING", false);
              }
            },
            (error) => {
              console.log(error);
              this.$store.commit("SET_LOADING", false);
            }
          );
        }
      },
      getData() {
        this.$store.commit("SET_LOADING", true);
        return MenuItemsService.index().then(
          (response) => {
            console.log("getdata");
            console.group(response);
            if (response.data.code == 200) {
              this.resources = response.data.data.data;
            } else {
              console.log(response.data.error);
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      },
    },
  };
  </script>
  