// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class InventoryUnitService {
     getAll() {
        return axios.get(VUE_APP_BASE_URL + 'inventory-unit', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'inventory-unit', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'inventory-unit/' + resource.ID, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'inventory-unit/' + resource.ID, { headers: authHeader() })
    }
}

export default new InventoryUnitService()