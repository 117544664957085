// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class InventoryLocationService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'inventory-location', { headers: authHeader() })
    }
    store(item) {
        return axios.post(VUE_APP_BASE_URL + 'inventory-location', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(VUE_APP_BASE_URL + 'inventory-location/' + item.ID, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(VUE_APP_BASE_URL + 'inventory-location/' + item.ID, { headers: authHeader() })
    }
}

export default new InventoryLocationService()