<template>
  <div>
    <div class="card mb-3" id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Permissions
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all rights
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-none" id="orders-bulk-actions">
              <div class="d-flex">
                <select class="form-select form-select-sm" aria-label="Bulk actions">
                  <option selected="">Bulk actions</option>
                  <option value="Refund">Refund</option>
                  <option value="Delete">Delete</option>
                  <option value="Archive">Archive</option>
                </select><button class="btn btn-falcon-default btn-sm ms-2" type="button">
                  Apply
                </button>
              </div>
            </div>
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1">
                <input class="form-control search-input fuzzy-search" type="text" placeholder="Search..."
                  aria-label="Search" v-model="searchValue" />
                <span class="fas fa-search search-box-icon"></span>
              </div>

              <button class="btn btn-falcon-warning btn-sm ms-2 rounded-pil h1" type="button" data-bs-toggle="modal"
                data-bs-target="#add-modal">
                <span class="fas fa-plus" data-fa-transform=""></span><span class="d-none d-sm-inline-block ms-1">New
                  Permission</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable id="tour-step-1" table-class-name="customize-table" alternating buttons-pagination
          :headers="headers" :items="resources" :search-field="searchField" :search-value="searchValue"
          :loading="loading">
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-title="item">
            <div class="my-2">
              <strong>{{ item.title }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.CREATED_AT) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" id="order-dropdown-0"
                data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                <div class="py-2">
                  <a class="dropdown-item" role="button" data-bs-toggle="modal" data-bs-target="#edit-modal" @click="editItem(item)">Edit</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" role="button" @click="deleteRecource(item)">Delete</a>
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div class="modal fade" id="edit-modal" data-bs-keyboard="false" data-bs-backdrop="static"
      tabindex="-1" 
      aria-labelledby="edit-modal Label" aria-hidden="true">
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="
                          btn-close btn btn-sm btn-circle
                          d-flex
                          flex-center
                          transition-base
                        " data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit right</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name">Title</label>
                        <input class="form-control" id="recipient-name" type="text" :placeholder="formData.title"
                          v-model="formData.title" :class="
                            v$.formData.title.$dirty &&
                              v$.formData.title.$invalid
                              ? `is-invalid`
                              : v$.formData.title.$dirty
                                ? `is-valid`
                                : ``
                          " />
                        <div v-for="error of v$.formData.title.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text">Details</label>
                        <input class="form-control" id="recipient-name" type="text" :placeholder="formData.detail"
                          v-model="formData.detail" :class="
                            v$.formData.detail.$dirty &&
                              v$.formData.detail.$invalid
                              ? `is-invalid`
                              : v$.formData.detail.$dirty
                                ? `is-valid`
                                : ``
                          " />
                        <div v-for="error of v$.formData.detail.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 mt-8">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button" @click="updateResource">
              <span class="fa fa-save me-2" data-fa-transform=""></span><span class="d-none d-sm-inline-block ms-1">Update
                right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div class="modal fade" id="add-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="
                        btn-close btn btn-sm btn-circle
                        d-flex
                        flex-center
                        transition-base
                      " data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new rights</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">

                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name">Title</label>
                          <input class="form-control" id="recipient-name" type="text" placeholder="e.g title"
                            v-model="formData.title" :class="
                              v$.formData.title.$dirty &&
                                v$.formData.title.$invalid
                                ? `is-invalid`
                                : v$.formData.title.$dirty
                                  ? `is-valid`
                                  : ``
                            " />
                          <div v-for="error of v$.formData.title.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text">Detail</label>
                          <input class="form-control" id="recipient-name" type="text" placeholder="detail"
                            v-model="formData.detail" :class="
                              v$.formData.detail.$dirty &&
                                v$.formData.detail.$invalid
                                ? `is-invalid`
                                : v$.formData.detail.$dirty
                                  ? `is-valid`
                                  : ``
                            " />
                          <div v-for="error of v$.formData.detail.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">

                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a @click="toggleActive" class="nav-link nav-link-card-detail" role="button"
                        :class="formData.VISIBLE ? `bg-info text-white` : ``"><span
                          class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <br />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button" @click="saveResource">
              <span class="fa fa-save me-2" data-fa-transform=""></span><span class="d-none d-sm-inline-block ms-1">Save
                right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuItemSizeService from "../services/menu_item_size.service";
import RightService from "../services/right.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        detail: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      menu_item_sizes: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        GROUP_ID: "",
        ACTIVE: 0,
        VISIBLE: 1,
        UNIT_title: "",
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "ID", value: "id", sortable: true, widh: 10 },
        { text: "TITLE", value: "title", sortable: true },
        { text: "DETAIL", value: "detail", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
    // this.getGroups();
    // this.getMenuItemSize();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    editItem(item){
      console.log(item)
this.formData = item
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.detail.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      RightService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      //this.formData =this.formData.id;
      RightService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.formData = {
              GROUP_ID: "",
              ACTIVE: 0,
              VISIBLE: 1,
              UNIT_title: "",
              SORT_ORDER: "",
              title: "",
              DESCRIPTION: "",
              detail: "",
              PRICE: "",
              STOCK_AMOUNT: "",
            };
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        RightService.delete(resource).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
            } else {
              console.log(response);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return RightService.index().then(
        (response) => {
          // console.group('resp');
          // console.log(response.data);
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
