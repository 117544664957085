<template>
    <footer class="footer">
        <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">Thank you for using Bulla <span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2022 &copy; <a href="https://bulla.co.ke">Bulla Softworks</a></p>
          </div>
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">v3.13.0</p>
          </div>
        </div>
      </footer>
</template>

<script>
export default {
    name: "FooterComp",
    created() {
    },
    methods: {
    },
}
</script>